<script setup lang="tsx">
import { useHead, useSeoMeta } from '@unhead/vue';
import { routes } from 'vue-router/auto-routes';

const VITE_BUILD_COMMIT = import.meta.env.VITE_BUILD_COMMIT;

definePage({
  name: 'Home',
  meta: {
    title: '首页',
    hidden: true,
  },
});

useHead({
  bodyAttrs: { class: { overflow: true } },
  title: 'Hello World',
  titleTemplate: (title) => `${title} | My App`,
  // Deduping
  // script: [{ key: '123', src: '/script.js' }],
});

useSeoMeta({
  title: 'vue-ts-example 首页',
  description: 'vue-ts-example 首页',
});

consola.info('routes', routes);

const FComponent: import('vue').FunctionalComponent<{ prop: string }> = (props /* context */) => (
  <>
    <a
      class="green"
      href="https://cn.vuejs.org/guide/extras/render-function#typing-functional-components"
      rel="noopener noreferrer"
      target="_blank"
    >
      函数式组件: https://cn.vuejs.org/guide/extras/render-function#typing-functional-components
    </a>
    <p>函数式组件，接收到的 prop 值为：</p>
    <pre>{JSON.stringify(props, null, 2)}</pre>
  </>
);
</script>

<template>
  <div b="1px solid pink" mt-2 p-2>
    <ul>
      <li>
        <router-link class="green" :to="{ name: 'DataLoadersId', params: { id: 520 } }">Data Loaders</router-link>
      </li>
    </ul>
  </div>
  <div b="1px solid pink" mt-2 p-2>
    <FComponent prop="Hello World" style="margin-top: 1rem"></FComponent>
  </div>

  <SendSms class="mt-2!" />
  <div b="1px solid pink" mt-2 p-2>commit: {{ VITE_BUILD_COMMIT }}</div>
</template>
