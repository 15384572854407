<script setup lang="ts">
import type { ThemeConfig } from 'ant-design-vue/es/config-provider/context';

import { theme } from 'ant-design-vue';

import { useLayout } from './layouts/sakai-vue/composables/layout';

const { isDarkTheme } = useLayout();

const themeConfig = computed(() => {
  return {
    algorithm: isDarkTheme.value ? theme.darkAlgorithm : theme.defaultAlgorithm,
  } satisfies ThemeConfig;
});
</script>

<template>
  <a-config-provider :theme="themeConfig">
    <RouterView />
  </a-config-provider>

  <DynamicDialog /> <ConfirmDialog /> <Toast />
</template>

<style>
.layout-wrapper {
  isolation: isolate;
}
@supports (height: 100dvh) {
  .h-screen {
    height: 100dvh;
  }
}
@supports (width: 100dvw) {
  .w-screen {
    width: 100dvw;
  }
}
</style>
