import { faker } from '@faker-js/faker';
// fake/user.fake.ts
import Mock from 'mockjs';
import { defineFakeRoute } from 'vite-plugin-fake-server/client';

export default defineFakeRoute([
  {
    response: () => {
      return Mock.mock({
        avatar: '@image("200x200")',
        email: '@email',
        id: '@guid',
        role: 'admin',
        username: '@first',
      });
    },
    url: '/mock/get-user-info',
  },
  {
    response: () => {
      return {
        avatar: faker.image.avatar(),
        birthday: faker.date.birthdate(),
        email: faker.internet.email(),
        firstName: faker.person.firstName(),
        id: faker.string.uuid(),
        lastName: faker.person.lastName(),
        role: 'admin',
        sex: faker.person.sexType(),
      };
    },
    url: '/fake/get-user-info',
  },
]);
