<script lang="ts">
import { defineBasicLoader } from 'unplugin-vue-router/data-loaders/basic';
// https://uvr.esm.is/data-loaders/rfc.html

export const usePageData = defineBasicLoader(
  'DataLoadersId',
  async (route, ...otherArguments) => {
    console.log('[DefineLoaderFn]', 'otherArgs :>>', otherArguments);
    await new Promise((resolve) => setTimeout(resolve, 777));
    return { idFromPreviousPage: route.params.id, someOtherData: 'someOtherData' };
  },
  {
    commit: 'immediate',
    lazy: false,
    // - `immediate`: the data is committed as soon as it is loaded.
    // - `after-load`: the data is committed after all non-lazy loaders have finished loading.
    // - `immediate`：数据在加载后立即提交。
    // - `after-load`：数据在所有非惰性加载器加载完成后提交。
  },
);
</script>

<script setup lang="ts">
definePage({
  meta: {
    metaKey: 'metaValue',
  },
});
const {
  data: pageData, // the data returned by the loader
  error, // an error object if the loader failed
  isLoading, // a boolean indicating if the loader is fetching data
  reload, // a function to refetch the data without navigating
} = usePageData();

const route = useRoute('DataLoadersId');

watch(
  pageData,
  (pageDataVal) => {
    const message = [`[watch]`, `pageDataVal :>> `, pageDataVal];
    if (pageDataVal === undefined) {
      // hot updated 会造成 pageDataVal 为 undefined
      console.warn(message);
    } else {
      console.debug(message);
    }
  },
  { immediate: true },
);
</script>

<template>
  <h1>Data Loaders</h1>
  <ul>
    <li>
      <RouterLink class="green" :to="{ name: 'DataLoadersIdSub1UserId', params: { id: route.params.id, userId: '1' } }"
        >sub-1</RouterLink
      >
    </li>
  </ul>
  <div flex="~ row">
    <button class="green" @click="$router.back()">Back</button>
    <button class="green" @click="reload()">Reload</button>
  </div>
  <main>
    <p v-if="isLoading">Loading...</p>
    <template v-else-if="error">
      <p>{{ error.message }}</p>
      <button class="green" @click="reload()">Retry</button>
    </template>
    <template v-else>
      <p>{{ pageData }}</p>
    </template>
  </main>
</template>

<route lang="yaml">
meta:
  // layout: second
</route>
