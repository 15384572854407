import { computed, reactive, watch, type WatchHandle } from 'vue';

const layoutConfig = reactive({
  darkTheme: false,
  menuMode: 'static',
  preset: 'Aura',
  primary: 'emerald',
  surface: null,
});

// 从 localStorage 读取初始状态，如果没有则使用默认值 false
const getStoredMenuState = (): boolean => {
  const stored = localStorage.getItem('staticMenuDesktopInactive');
  return stored ? JSON.parse(stored) : false;
};

const layoutState = reactive({
  activeMenuItem: null,
  configSidebarVisible: false,
  menuHoverActive: false,
  overlayMenuActive: false,
  profileSidebarVisible: false,
  staticMenuDesktopInactive: getStoredMenuState(),
  staticMenuMobileActive: false,
});
let _watcher: WatchHandle;
export function useLayout() {
  if (!_watcher) {
    // 监听 staticMenuDesktopInactive 的变化并保存到 localStorage
    _watcher = watch(
      () => layoutState.staticMenuDesktopInactive,
      (newValue) => {
        localStorage.setItem('staticMenuDesktopInactive', JSON.stringify(newValue));
      },
    );
  }

  const setActiveMenuItem = (item: Record<string, never>) => {
    layoutState.activeMenuItem = item.value || item;
  };

  const toggleDarkMode = () => {
    if (!document.startViewTransition) {
      executeDarkModeToggle();

      return;
    }

    document.startViewTransition(() => executeDarkModeToggle());
  };

  const executeDarkModeToggle = () => {
    layoutConfig.darkTheme = !layoutConfig.darkTheme;
    document.documentElement.classList.toggle('app-dark');
  };

  const toggleMenu = () => {
    if (layoutConfig.menuMode === 'overlay') {
      layoutState.overlayMenuActive = !layoutState.overlayMenuActive;
    }

    if (window.innerWidth > 991) {
      layoutState.staticMenuDesktopInactive = !layoutState.staticMenuDesktopInactive;
    } else {
      layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive;
    }
  };

  const isSidebarActive = computed(() => layoutState.overlayMenuActive || layoutState.staticMenuMobileActive);

  const isDarkTheme = computed(() => layoutConfig.darkTheme);

  const getPrimary = computed(() => layoutConfig.primary);

  const getSurface = computed(() => layoutConfig.surface);

  return {
    getPrimary,
    getSurface,
    isDarkTheme,
    isSidebarActive,
    layoutConfig,
    layoutState,
    setActiveMenuItem,
    toggleDarkMode,
    toggleMenu,
  };
}
