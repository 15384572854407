import _definePage_default_0 from '/workspace/examples/vue-ts-example/src/pages/data-loaders.[id]/index.page.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/workspace/examples/vue-ts-example/src/pages/Home.page.vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/workspace/examples/vue-ts-example/src/pages/UI-components/infinite-loading/detail.page.vue?definePage&vue&lang.tsx'

export const routes = [
  {
    path: '/',
    name: 'Root',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/index.page.vue'),
    /* no children */
    meta: {
      "layout": false
    }
  },
  {
    path: '/:path(.*)',
    name: '$Path',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/[...path].page.vue'),
    props: true,
    /* no children */
    meta: {
      "layout": false
    }
  },
  {
    path: '/cesium-viewer',
    name: 'CesiumViewer',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/cesium-viewer.page.vue'),
    /* no children */
    meta: {
      "layout": false
    }
  },
  {
    path: '/data-loaders/:id',
    /* internal name: 'DataLoadersId' */
    /* no component */
    children: [
      _mergeRouteRecord(
        {
          path: '',
          name: 'DataLoadersId',
          component: () => import('/workspace/examples/vue-ts-example/src/pages/data-loaders.[id]/index.page.vue'),
          /* no children */
          meta: {
            "// layout": "second"
          }
        },
        _definePage_default_0
      ),
      {
        path: 'sub-1/:userId',
        name: 'DataLoadersIdSub1UserId',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/data-loaders.[id]/sub-1.[userId].page.vue'),
        /* no children */
        meta: {
          "// layout": "second"
        }
      }
    ],
  },
  {
    path: '/FlowbiteSidebar',
    name: 'FlowbiteSidebar',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/FlowbiteSidebar.page.vue'),
    /* no children */
    meta: {
      "layout": false
    }
  },
  _mergeRouteRecord(
    {
      path: '/Home',
      name: 'Home',
      component: () => import('/workspace/examples/vue-ts-example/src/pages/Home.page.vue'),
      /* no children */
    },
    _definePage_default_2
  ),
  {
    path: '/Page',
    /* internal name: 'Page' */
    /* no component */
    children: [
      {
        path: 'API',
        name: 'PageAPI',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/Page/API.page.vue'),
        /* no children */
      },
      {
        path: 'fonts',
        name: 'PageFonts',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/Page/fonts.page.vue'),
        /* no children */
      },
      {
        path: 'Icons',
        name: 'PageIcons',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/Page/Icons.page.vue'),
        /* no children */
      },
      {
        path: 'JSPage',
        /* internal name: 'PageJSPage' */
        /* no component */
        children: [
          {
            path: '',
            name: 'PageJSPage',
            component: () => import('/workspace/examples/vue-ts-example/src/pages/Page/JSPage/index.page.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'MDPage',
        name: 'PageMDPage',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/Page/MDPage.page.md'),
        /* no children */
      },
      {
        path: 'Style',
        /* internal name: 'PageStyle' */
        /* no component */
        children: [
          {
            path: '',
            name: 'PageStyle',
            component: () => import('/workspace/examples/vue-ts-example/src/pages/Page/Style/index.page.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/PkgsUsage',
    /* internal name: 'PkgsUsage' */
    /* no component */
    children: [
      {
        path: 'I18n',
        name: 'PkgsUsageI18n',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/PkgsUsage/I18n.page.vue'),
        /* no children */
      },
      {
        path: 'ts-enum-util',
        name: 'PkgsUsageTsEnumUtil',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/PkgsUsage/ts-enum-util.page.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/UI-components',
    /* internal name: 'UIComponents' */
    /* no component */
    children: [
      {
        path: 'AntdV',
        /* internal name: 'UIComponentsAntdV' */
        /* no component */
        children: [
          {
            path: '',
            name: 'UIComponentsAntdV',
            component: () => import('/workspace/examples/vue-ts-example/src/pages/UI-components/AntdV/index.page.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'Components',
        /* internal name: 'UIComponentsComponents' */
        /* no component */
        children: [
          {
            path: '',
            name: 'UIComponentsComponents',
            component: () => import('/workspace/examples/vue-ts-example/src/pages/UI-components/Components/index.page.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'infinite-loading',
        /* internal name: 'UIComponentsInfiniteLoading' */
        /* no component */
        children: [
          {
            path: '',
            name: 'UIComponentsInfiniteLoading',
            component: () => import('/workspace/examples/vue-ts-example/src/pages/UI-components/infinite-loading/index.page.vue'),
            /* no children */
          },
          _mergeRouteRecord(
            {
              path: 'detail',
              name: 'UIComponentsInfiniteLoadingDetail',
              component: () => import('/workspace/examples/vue-ts-example/src/pages/UI-components/infinite-loading/detail.page.vue'),
              /* no children */
            },
            _definePage_default_3
          )
        ],
      },
      {
        path: 'InspiraUI',
        /* internal name: 'UIComponentsInspiraUI' */
        /* no component */
        children: [
          {
            path: '',
            name: 'UIComponentsInspiraUI',
            component: () => import('/workspace/examples/vue-ts-example/src/pages/UI-components/InspiraUI/index.page.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'PrimeVue',
        /* internal name: 'UIComponentsPrimeVue' */
        /* no component */
        children: [
          {
            path: '',
            name: 'UIComponentsPrimeVue',
            component: () => import('/workspace/examples/vue-ts-example/src/pages/UI-components/PrimeVue/index.page.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'ShadcnVue',
        /* internal name: 'UIComponentsShadcnVue' */
        /* no component */
        children: [
          {
            path: '',
            name: 'UIComponentsShadcnVue',
            component: () => import('/workspace/examples/vue-ts-example/src/pages/UI-components/ShadcnVue/index.page.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/VueMacros',
    /* internal name: 'VueMacros' */
    /* no component */
    children: [
      {
        path: 'DefineRender',
        name: 'VueMacrosDefineRender',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/VueMacros/DefineRender.page.vue'),
        /* no children */
      },
      {
        path: 'ReactivityTransform',
        name: 'VueMacrosReactivityTransform',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/VueMacros/ReactivityTransform.page.vue'),
        /* no children */
      },
      {
        path: 'ReusableTemplate',
        name: 'VueMacrosReusableTemplate',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/VueMacros/ReusableTemplate.page.vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

